import React from "react";

const Loader: React.FC<any> = () => {
  return (
    <div className="Fl-pageloader loader-clw">
      <section>
        <div className="loader loader-1">
          <div className="loader-outter"></div>
          <div className="loader-inner"></div>
        </div>
      </section>
    </div>
  );
};

export const ComponentLoader: React.FC<any> = () => {
  return (
    <div className="Fl-pageloader loader-clw" style={{ opacity: 0.5 }}>
      <section>
        <div className="loader loader-1">
          <div className="loader-outter"></div>
          <div className="loader-inner"></div>
        </div>
      </section>
    </div>
    // <div
    //   style={{
    //     position: "absolute",
    //     top: 0,
    //     left: 0,
    //     right: 0,
    //     // width: "100%",
    //     height: "100%",
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "center",
    //     background: "black",
    //     boxSizing: "border-box",
    //     zIndex: 10000000000,
    //     opacity: 0.1,
    //   }}>
    //   <b
    //     style={{
    //       // zIndex: 100000000001,
    //       // opacity: 1,
    //       color: "white",
    //       // position: "relative",
    //     }}>
    //     Loading ...
    //   </b>
    // </div>
  );
};

export default Loader;
