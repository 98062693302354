import React, { useEffect, useState } from "react";
import { Dropdown, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { closeSideNav, openSideNav } from "../../store/slices/theme";
import ROUTE_PATH from "../../route/routes";
import logo from "../../assets/img/footerlogo.png";
import profileImgDummy from "../../assets/img/default-user.png";
import AuthService from "../../services/auth.service";
import ConfirmBox from "../confirmBox";
import UserTypes from "../../config/userTypes";

const MobileHeader: React.FC<any> = (props: any) => {
  const { isSideNavOpen } = useAppSelector((state: any) => state.theme);
  const { user, profile } = useAppSelector((state: any) => state.user);
  const dispatch = useAppDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const location = useLocation();
  useEffect(() => {
    dispatch(closeSideNav());
  }, [location.pathname]);

  return (
    <Navbar className="sb-topnav navbar navbar-expand navbar-light bg-white c__nav">
      {user?.role !== UserTypes.AGENT && (
        <Link className="navbar-brand" to={ROUTE_PATH.PUBLIC.HOME}>
          <img src={logo} className="img-fluid " alt="login-img" />
        </Link>
      )}
      <button
        className="btn btn-link btn-sm order-1 order-lg-0 hmr_btn"
        id="sidebarToggle"
        onClick={() =>
          isSideNavOpen ? dispatch(closeSideNav()) : dispatch(openSideNav())
        }>
        <i className="fa fa-bars" id="sidebarToggleIcon"></i>
      </button>
      <ul className="navbar-nav ml-auto mr-0 mr-md-3 my-2 my-md-0">
        {/* <li className="nav-item dropdown">
          <a
            className="nav-link navlink-item"
            id="userDropdown"
            href="#"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <i className="fa fa-bell-o" aria-hidden="true"></i>
          </a>
        </li> */}

        <Dropdown
          show={dropdownOpen}
          onToggle={() => setDropdownOpen(!dropdownOpen)}
          className="nav-item nan-link-profile dropdown">
          <Dropdown.Toggle className="nav-link cursor-pointer " as="div">
            <img
              src={profile?.photo?.url || profileImgDummy}
              className="rounded-circle"
            />
          </Dropdown.Toggle>

          <Dropdown.Menu
            className="dropdown-menu-right"
            aria-labelledby="userDropdown">
            <a
              className="dropdown-item"
              onClick={(e: any) => {
                e.preventDefault();
                const proceedHandler = () => {
                  AuthService.logout();
                };
                dispatch(closeSideNav());
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <ConfirmBox
                        onClose={onClose}
                        onProceed={proceedHandler}
                        message={`Are you sure you want to logout?`}
                      />
                    );
                  },
                });
              }}>
              Logout
            </a>
          </Dropdown.Menu>
        </Dropdown>
      </ul>
    </Navbar>
  );
};

export default MobileHeader;
