import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import Collapsible from "react-collapsible";

import logo from "../../../assets/img/footerlogo.png";
import ROUTE_PATH from "../../../route/routes";
import useUserNavigate from "../../../utils/useUserNavigate";
import PrivateNavData from "../../../data/privateNavData";
import AuthService from "../../../services/auth.service";
import { closeSideNav } from "../../../store/slices/theme";
import ConfirmBox from "../../confirmBox";
import { useAppDispatch } from "../../../store/hooks";
import useOutsideSideNavbar from "../../../utils/useOutsideSideNavbar";

const ChannelPartnerSideNavbar: React.FC = () => {
  const { userNavigateLink } = useUserNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const ref = useRef(null);
  useOutsideSideNavbar(ref);
  return (
    <div id="layoutSidenav_nav" ref={ref}>
      <nav
        className="sb-sidenav accordion sb-sidenav-light"
        id="sidenavAccordion">
        <div className="sb-sidenav-header">
          <Link className="navbar-brand" to={ROUTE_PATH.PUBLIC.HOME}>
            <img src={logo} className="img-fluid " alt="login-img" />
          </Link>
        </div>
        <div className="sb-sidenav-menu">
          <Link
            className={`nav-link icon-1  ${
              location.pathname ===
              userNavigateLink(ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.DASHBOARD)
                ? "nav-link-active"
                : ""
            }`}
            to={userNavigateLink(ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.DASHBOARD)}>
            Dashboard
          </Link>
          {PrivateNavData.CHANNEL_PARTNER.map((item: any) => (
            <Collapsible
              triggerWhenOpen={
                item?.isLink ? (
                  <Link
                    to={item?.link}
                    className={`cursor-pointer ${item.className} ${
                      location.pathname.includes(item.link)
                        ? "nav-link-active"
                        : ""
                    } `}>
                    {item.label}{" "}
                    {item?.children && (
                      <FontAwesomeIcon icon={faAngleDown} color="#adb5bd" />
                    )}
                  </Link>
                ) : (
                  <div
                    className={`cursor-pointer ${item.className} ${
                      location.pathname.includes(item.link)
                        ? "nav-link-active"
                        : ""
                    } `}>
                    {item.label}{" "}
                    {item?.children && (
                      <FontAwesomeIcon icon={faAngleDown} color="#adb5bd" />
                    )}
                  </div>
                )
              }
              trigger={
                item?.isLink ? (
                  <Link
                    to={item?.link}
                    className={`cursor-pointer  ${item.className} ${
                      location.pathname.includes(item.link)
                        ? "nav-link-active"
                        : ""
                    } `}>
                    {item.label}{" "}
                    {item?.children && (
                      <FontAwesomeIcon icon={faAngleRight} color="#adb5bd" />
                    )}
                  </Link>
                ) : (
                  <div
                    className={`cursor-pointer  ${item.className} ${
                      location.pathname.includes(item.link)
                        ? "nav-link-active"
                        : ""
                    } `}>
                    {item.label}{" "}
                    {item?.children && (
                      <FontAwesomeIcon icon={faAngleRight} color="#adb5bd" />
                    )}
                  </div>
                )
              }>
              {item?.children && (
                <nav className="sb-sidenav-menu-nested nav collapse show">
                  {item?.children.map((it: any) => (
                    <Link
                      className={`nav-link `}
                      style={
                        location.pathname.includes(it.link)
                          ? { color: "#00d2d3" }
                          : {}
                      }
                      to={it.link}>
                      {it.label}
                    </Link>
                  ))}
                </nav>
              )}
            </Collapsible>
          ))}
          <a
            className={`nav-link  icon-8  cursor-pointer`}
            onClick={(e: any) => {
              e.preventDefault();
              const proceedHandler = () => {
                AuthService.logout();
              };

              dispatch(closeSideNav());
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <ConfirmBox
                      onClose={onClose}
                      onProceed={proceedHandler}
                      message={`Are you sure you want to logout?`}
                    />
                  );
                },
              });
            }}>
            Logout
          </a>
        </div>
        {/* <div className="sb-sidenav-footer cursor-pointer ">
          <a
            className="nav-link icon-8 icon-6 collapsed"
            onClick={(e: any) => {
              e.preventDefault();
              const proceedHandler = () => {
                AuthService.logout();
              };

              dispatch(closeSideNav());
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <ConfirmBox
                      onClose={onClose}
                      onProceed={proceedHandler}
                      message={`Are you sure you want to logout?`}
                    />
                  );
                },
              });
            }}>
            Logout
          </a>
        </div> */}
      </nav>
    </div>
  );
};

export default ChannelPartnerSideNavbar;
