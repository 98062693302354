import { useEffect } from "react";

import { useAppDispatch } from "../store/hooks";
import { closeSideNav } from "../store/slices/theme";

export default function useOutsideSideNavbar(ref: any) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (
          event.target.id !== "sidebarToggleIcon" &&
          event.target.id !== "sidebarToggle"
        )
          dispatch(closeSideNav());
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
