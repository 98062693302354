import { useLocation, useNavigate } from "react-router-dom";
import ROUTE_PATH from "../route/routes";
import { useAppSelector } from "../store/hooks";

const useUserNavigate = () => {
  const { user, isAuthenticated } = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  const userNavigate = (path: string) => {
    if (path === "back") {
      navigate(-1);
      return;
    }
    if (isAuthenticated) {
      navigate(
        `${ROUTE_PATH.PRIVATE.MAIN.replace(":userType", user?.role)}/${path}`,
      );
    } else {
      navigate(path);
    }
  };

  const userNavigateLink = (path: string) => {
    if (isAuthenticated) {
      return `${ROUTE_PATH.PRIVATE.MAIN.replace(
        ":userType",
        user?.role,
      )}/${path}`;
    } else {
      return path;
    }
  };

  return {
    userNavigate,
    userNavigateLink,
  };
};

export default useUserNavigate;
