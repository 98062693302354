import ROUTE_PATH from "../../route/routes";

const PrivateNavData = {
  ADMIN: [
    {
      id: "navAdmindata1",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-manage_users collapsed",
      label: "Manage Users",
      // isLink: true,
      link: ROUTE_PATH.PRIVATE.ADMIN.MANAGE_USERS.MAIN,
      children: [
        {
          id: "navAdmindata1child1",
          label: "Direct Customer",
          link: ROUTE_PATH.PRIVATE.ADMIN.MANAGE_USERS.MAIN,
        },
        {
          id: "navAdmindata1child2",
          label: "Agents",
          link: ROUTE_PATH.PRIVATE.ADMIN.MANAGE_AGENTS,
        },
        {
          id: "navAdmindata1child3",
          label: "OTP Verified Users",
          link: ROUTE_PATH.PRIVATE.ADMIN.MANAGE_OTP_USERS,
        },
        {
          id: "navAdmindata1child4",
          label: "Referal Partner", 
          link: ROUTE_PATH.PRIVATE.ADMIN.MANAGE_FOREX_PARTNER,
        },
      ]
    },
    {
      id: "navAdmindata2",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-new_users collapsed",
      label: "New Users",
      isLink: true,
      link: ROUTE_PATH.PRIVATE.ADMIN.NEW_USER.MAIN,
    },
    {
      id: "navAdmindata3",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-archived_users collapsed",
      label: "Archived Users",
      isLink: true,
      link: ROUTE_PATH.PRIVATE.ADMIN.ARCHIVED_USERS,
    },
    {
      id: "navAdmindata4",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-me_rate collapsed",
      label: "Manage Exchange Rates",
      isLink: true,
      link: ROUTE_PATH.PRIVATE.ADMIN.MANAGE_EXCHANGE_RATES,
    },
    {
      id: "navAdmindata5",
      className:
        "d-flex align-items-center justify-content-between nav-link  icon-manage_reports collapsed",
      label: "Manage Reports",
      isLink: true,
      link: ROUTE_PATH.PRIVATE.ADMIN.MANAGE_REPORTS,
    },
    {
      id: "navAdmindata6",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-manage_orders collapsed",
      label: "Manage Orders",
      isLink: true,
      link: ROUTE_PATH.PRIVATE.ADMIN.MANAGE_ORDERS,
    },
    // {
    //   id: "navAdmindata7",
    //   className:
    //     "d-flex align-items-center justify-content-between nav-link icon-master_tables collapsed",
    //   label: "Master Tables",
    //   isLink: true,
    //   link: ROUTE_PATH.PRIVATE.ADMIN.MASTER_TABLES,
    // },
    {
      id: "navAdmindata8",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-pb_allocation collapsed",
      label: "Partner Bank Allocation",
      isLink: true,
      link: ROUTE_PATH.PRIVATE.ADMIN.PARTNER_BANK_ALLOCATION,
    },
    {
      id: "navAdmindata9",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-create_au collapsed",
      label: "Create Admin Users",
      isLink: true,
      link: ROUTE_PATH.PRIVATE.ADMIN.CREATE_ADMIN_USERS,
    },
    {
      id: "navAdmindata10",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-7 collapsed",
      label: "Settings",
      link: ROUTE_PATH.PRIVATE.ADMIN.SETTINGS.MAIN,
      children: [
        {
          id: "navAdmindata10child1",
          label: "My Profile",
          link: ROUTE_PATH.PRIVATE.ADMIN.SETTINGS.MY_PROFILE,
        },
        {
          id: "navadmindata10child2",
          label: "Change Password",
          link: ROUTE_PATH.PRIVATE.ADMIN.SETTINGS.CHANGE_PASSWORD,
        },
        {
          id: "navAdmindata10child3",
          label: "Forex Partner Group",
          link: ROUTE_PATH.PRIVATE.ADMIN.SETTINGS.CHANNEL_PARTNER_GROUP,
        },
      ],
    },
  ],
  STAFF: [
    {
      id: "navStaffdata1",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-manage_users collapsed",
      label: "Manage Users",
      isLink: true,
      link: ROUTE_PATH.PRIVATE.STAFF.MANAGE_USERS.MAIN,
    },
    {
      id: "navStaffdata2",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-manage_orders collapsed",
      label: "Manage Orders",
      isLink: true,
      link: ROUTE_PATH.PRIVATE.STAFF.MANAGE_ORDERS,
    },
    {
      id: "navStaffdata3",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-7 collapsed",
      label: "Settings",
      link: ROUTE_PATH.PRIVATE.STAFF.SETTINGS.MAIN,
      children: [
        {
          id: "navStaffdata3child1",
          label: "My Profile",
          link: ROUTE_PATH.PRIVATE.STAFF.SETTINGS.MY_PROFILE,
        },
      ],
    },
  ],
  CUSTOMER: [
    // {
    //   id: "navcustomerdata2",
    //   className:
    //     "d-flex align-items-center justify-content-between nav-link icon-2 collapsed",
    //   label: "Send Money Abroad",
    //   link: ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.MAIN,
    //   children: [
    //     {
    //       id: "navcustomerdata2child1",
    //       label: "New Fund Transfer",
    //       link: ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.NEW_FUND_TRANSFER
    //         .BLOCK_LIVE_RATE,
    //     },
    //     {
    //       id: "navcustomerdata2child2",
    //       label: "All Receivers",
    //       link: ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.ALL_RECEIVERS,
    //     },
    //     {
    //       id: "navcustomerdata2child3",
    //       label: "Create Receiver",
    //       link: ROUTE_PATH.PRIVATE.CUSTOMER.SEND_MONEY_ABROAD.CREATE_RECEIVER,
    //     },
    //   ],
    // },
    // {
    //   id: "navcustomerdata3",
    //   className:
    //     "d-flex align-items-center justify-content-between nav-link icon-3 collapsed",
    //   label: "Currency Exchange",
    //   link: ROUTE_PATH.PRIVATE.CUSTOMER.CURRENCY_EXCHANGE.MAIN,
    //   children: [
    //     {
    //       id: "navcustomerdata3child1",
    //       label: "Recent Exchanges",
    //       link: ROUTE_PATH.PRIVATE.CUSTOMER.CURRENCY_EXCHANGE.RECENT_EXCHANGES,
    //     },
    //     {
    //       id: "navcustomerdata3child1",
    //       label: "New Exchange",
    //       link: ROUTE_PATH.PRIVATE.CUSTOMER.CURRENCY_EXCHANGE
    //         .SELECT_PURPOSE_CURRENCY,
    //     },
    //   ],
    // },
    {
      id: "navcustomerdata4",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-4 collapsed",
      label: "Travel Card",
      link: ROUTE_PATH.PRIVATE.CUSTOMER.TRAVEL_CARD.MAIN,
      children: [
        {
          id: "navcustomerdata4child1",
          label: "Book A Travel Card",
          link: ROUTE_PATH.PRIVATE.CUSTOMER.TRAVEL_CARD.MAIN,
        },
      ],
    },
    // {
    //   id: "navcustomerdata5",
    //   className:
    //     "d-flex align-items-center justify-content-between nav-link icon-5 collapsed",
    //   label: "Profile",
    //   children: [
    //     {
    //       id: "navcustomerdata5child1",
    //       label: "Static Navigation",
    //       link: "",
    //     },
    //     {
    //       id: "navcustomerdata5child2",
    //       label: "Light Navigation",
    //       link: "",
    //     },
    //   ],
    // },
    // {
    //   id: "navcustomerdata6",
    //   className:
    //     "d-flex align-items-center justify-content-between nav-link icon-6 collapsed",
    //   label: "Refer a friend",
    // },
    {
      id: "navcustomerdata7",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-bell collapsed",
      label: "Rate Alerts",
      isLink: true,
      link: ROUTE_PATH.PRIVATE.CUSTOMER.RATE_ALERTS,
    },
    {
      id: "navcustomerdata7",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-7 collapsed",
      label: "Settings",
      link: ROUTE_PATH.PRIVATE.CUSTOMER.SETTINGS.MAIN,
      children: [
        {
          id: "navcustomerdata7child1",
          label: "Change Password",
          link: ROUTE_PATH.PRIVATE.CUSTOMER.SETTINGS.CHANGE_PASSWORD,
        },
      ],
    },
  ],
  AGENT: [
    // {
    //   id: "navagentdata1",
    //   className:
    //     "d-flex align-items-center justify-content-between nav-link icon-2 collapsed",
    //   label: "Place an Order",
    //   link: ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.MAIN,
    //   isLink: false,
    //   children: [
    //     {
    //       id: "navagentdata1child1",
    //       label: "Send Money Abroad",
    //       link: ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.SEND_MONEY_ABROAD.MAIN,
    //       isLink: false,
    //       children: [
    //         {
    //           id: "navagentdata1child1child1",
    //           label: "New Fund Transfer",
    //           link: ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.SEND_MONEY_ABROAD
    //             .NEW_FUND_TRANSFER.MAIN,
    //         },
    //         {
    //           id: "navagentdata1child1child2",
    //           label: "Create Receiver",
    //           link: ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.SEND_MONEY_ABROAD
    //             .CREATE_RECEIVER,
    //         },
    //         {
    //           id: "navagentdata1child1child3",
    //           label: "All Receivers",
    //           link: ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.SEND_MONEY_ABROAD
    //             .ALL_RECEIVERS,
    //         },
    //       ],
    //     },
    //     {
    //       id: "navagentdata1child2",
    //       label: "Currency Exchange",
    //       link: ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.CURRENCY_EXCHANGE.MAIN,
    //     },
    //     {
    //       id: "navagentdata1child3",
    //       label: "Travel Card",
    //       link: ROUTE_PATH.PRIVATE.AGENT.PLACE_AN_ORDER.TRAVEL_CARD.MAIN,
    //     },
    //   ],
    // },
    {
      id: "navagentdata1",
      className:
      "d-flex align-items-center justify-content-between nav-link icon-new_users collapsed",
      label: "Manage Users",
      isLink: true,
      link: ROUTE_PATH.PRIVATE.AGENT.MANAGE_USERS,
    },
    {
      id: "navagentdata3",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-3 collapsed",
      label: "Manage Orders",
      isLink: true,
      link: ROUTE_PATH.PRIVATE.AGENT.MANAGE_ORDERS,
    },
    {
      id: "navagentdata2",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-pb_allocation collapsed",
      label: "Manage FX Rate",
      isLink: true,
      link: ROUTE_PATH.PRIVATE.AGENT.MANAGE_FX_RATE,
    },
    {
      id: "navagentdata3",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-7 collapsed",
      label: "Settings",
      link: ROUTE_PATH.PRIVATE.AGENT.SETTINGS.MAIN,
      children: [
        {
          id: "navagentdata3child1",
          label: "Agent Profile",
          link: ROUTE_PATH.PRIVATE.AGENT.SETTINGS.AGENT_PROFILE,
        },
        {
          id: "navagentdata3child2",
          label: "Change Password",
          link: ROUTE_PATH.PRIVATE.AGENT.SETTINGS.CHANGE_PASSWORD,
        },
        {
          id: "navagentdata3child3",
          label: "Affiliate Links",
          link: ROUTE_PATH.PRIVATE.AGENT.SETTINGS.AFFILIATE_LINKS,
        },
        {
          id: "navagentdata3child4",
          label: "Terms & Conditions",
          link: ROUTE_PATH.PRIVATE.AGENT.SETTINGS.TERMS_AND_CONDITIONS,
          // link: "",
        },
      ],
    },
  ],
  AGENT_BRANCH: [
    {
      id: "navagentdata1",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-2 collapsed",
      label: "Place an Order",
      link: ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.MAIN,
      isLink: false,
      children: [
        {
          id: "navagentdata1child1",
          label: "Send Money Abroad",
          link: ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD.MAIN,
          isLink: false,
          children: [
            {
              id: "navagentdata1child1child1",
              label: "New Fund Transfer",
              link: ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.MAIN,
            },
            {
              id: "navagentdata1child1child2",
              label: "Create Receiver",
              link: ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .CREATE_RECEIVER,
            },
            {
              id: "navagentdata1child1child3",
              label: "All Receivers",
              link: ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .ALL_RECEIVERS,
            },
          ],
        },
        // {
        //   id: "navagentdata1child2",
        //   label: "24/7 Remittance",
        //   link: ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.CURRENCY_EXCHANGE.MAIN,
        // },
        {
          id: "navagentdata1child3",
          label: "Travel Card",
          link: ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.TRAVEL_CARD.MAIN,
        },
      ],
    },
    // {
    //   id: "navagentdata3",
    //   className:
    //     "d-flex align-items-center justify-content-between nav-link icon-3 collapsed",
    //   label: "Manage Orders",
    //   isLink: true,
    //   link: ROUTE_PATH.PRIVATE.BRANCH.MANAGE_ORDERS,
    // },
    {
      id: "navagentdata3",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-7 collapsed",
      label: "Settings",
      link: ROUTE_PATH.PRIVATE.BRANCH.SETTINGS.MAIN,
      children: [
        // {
        //   id: "navagentdata3child1",
        //   label: "Agent Profile",
        //   link: ROUTE_PATH.PRIVATE.BRANCH.SETTINGS.AGENT_PROFILE,
        // },
        {
          id: "navagentdata3child2",
          label: "Change Password",
          link: ROUTE_PATH.PRIVATE.BRANCH.SETTINGS.CHANGE_PASSWORD,
        },
        {
          id: "navagentdata3child3",
          label: "Affiliate Links",
          link: ROUTE_PATH.PRIVATE.BRANCH.SETTINGS.AFFILIATE_LINKS,
        },
        {
          id: "navagentdata3child4",
          label: "Terms & Conditions",
          link: ROUTE_PATH.PRIVATE.BRANCH.SETTINGS.TERMS_AND_CONDITIONS,
          // link: "",
        },
      ],
    },
  ],
  AGENT_PARTNER: [
    {
      id: "navagentdata1",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-2 collapsed",
      label: "Place an Order",
      link: ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.MAIN,
      isLink: false,
      children: [
        {
          id: "navagentdata1child1",
          label: "Send Money Abroad",
          link: ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD.MAIN,
          isLink: false,
          children: [
            {
              id: "navagentdata1child1child1",
              label: "New Fund Transfer",
              link: ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .NEW_FUND_TRANSFER.MAIN,
            },
            {
              id: "navagentdata1child1child2",
              label: "Create Receiver",
              link: ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .CREATE_RECEIVER,
            },
            {
              id: "navagentdata1child1child3",
              label: "All Receivers",
              link: ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.SEND_MONEY_ABROAD
                .ALL_RECEIVERS,
            },
          ],
        },
        // {
        //   id: "navagentdata1child2",
        //   label: "24/7 Remittance",
        //   link: ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.CURRENCY_EXCHANGE.MAIN,
        // },
        {
          id: "navagentdata1child3",
          label: "Travel Card",
          link: ROUTE_PATH.PRIVATE.BRANCH.PLACE_AN_ORDER.TRAVEL_CARD.MAIN,
        },
      ],
    },
    // {
    //   id: "navagentdata3",
    //   className:
    //     "d-flex align-items-center justify-content-between nav-link icon-3 collapsed",
    //   label: "Manage Orders",
    //   isLink: true,
    //   link: ROUTE_PATH.PRIVATE.BRANCH.MANAGE_ORDERS,
    // },
    {
      id: "navagentdata3",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-7 collapsed",
      label: "Settings",
      link: ROUTE_PATH.PRIVATE.BRANCH.SETTINGS.MAIN,
      children: [
        {
          id: "navagentdata3child1",
          label: "Agent Profile",
          link: ROUTE_PATH.PRIVATE.BRANCH.SETTINGS.AGENT_PROFILE,
        },
        {
          id: "navagentdata3child2",
          label: "Change Password",
          link: ROUTE_PATH.PRIVATE.BRANCH.SETTINGS.CHANGE_PASSWORD,
        },
        {
          id: "navagentdata3child3",
          label: "Affiliate Links",
          link: ROUTE_PATH.PRIVATE.BRANCH.SETTINGS.AFFILIATE_LINKS,
        },
        {
          id: "navagentdata3child4",
          label: "Terms & Conditions",
          link: ROUTE_PATH.PRIVATE.BRANCH.SETTINGS.TERMS_AND_CONDITIONS,
          // link: "",
        },
      ],
    },
  ],
  CHANNEL_PARTNER: [
    {
      id: "navAdmindata6",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-manage_orders collapsed",
      label: "Manage Orders",
      isLink: true,
      link: ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.MANAGE_ORDERS,
    },
    {
      id: "navAdmindata6",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-manage_reports collapsed",
      label: "Manage Currencies",
      isLink: true,
      link: ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.MANAGE_CURRENCIES,
    },
    {
      id: "navStaffdata3",
      className:
        "d-flex align-items-center justify-content-between nav-link icon-7 collapsed",
      label: "Settings",
      link: ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.SETTINGS.MAIN,
      children: [
        {
          id: "navStaffdata3child1",
          label: "My Profile",
          link: ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.SETTINGS.MY_PROFILE,
        },
        {
          id: "navStaffdata3child2",
          label: "Change Password",
          link: ROUTE_PATH.PRIVATE.CHANNEL_PARTNER.SETTINGS.CHANGE_PASSWORD,
        },
      ],
    },
  ],
};
export default PrivateNavData;
