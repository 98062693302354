import { saveAs } from "file-saver";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { toast } from "react-toastify";
import StaticValues from "../config/staticValues";
import { useAppSelector } from "../store/hooks";
import PAGE_NAMES from "../route/page-names";

export const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const getDeviceId = async () => {
  const localVisitorId = localStorage.getItem("visitorId");
  if (localVisitorId) {
    return localVisitorId;
  } else {
    const fpPromise = FingerprintJS.load();
    try {
      const fp = await fpPromise;
      const result = await fp.get();
      if (await result.visitorId) {
        localStorage.setItem("visitorId", result.visitorId);
        return result.visitorId;
      }
    } catch (error) {
      alert(error);
    }
  }

  // Get the visitor identifier when you need it.
};

export const setInputFilter = (value: any) => {
  if (!value) return true;
  if (value === ".") return true;
  return /^\d*\.?\d*$/.test(value);
};

export const titleCase = (str: string) => {
  if (!str) return;
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  return splitStr.join(" ");
};

export function intToString(num: any) {
  if (!num) return;
  num = num.toString().replace(/[^0-9.]/g, "");
  if (num < 1000) {
    return num;
  }
  let si = [
    { v: 1e3, s: "K" },
    { v: 1e6, s: "M" },
    { v: 1e9, s: "B" },
    { v: 1e12, s: "T" },
    { v: 1e15, s: "P" },
    { v: 1e18, s: "E" },
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break;
    }
  }
  return (
    (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
    si[index].s
  );
}

export function removeEmptyFields(obj: any) {
  Object.keys(obj).forEach((k) => obj[k] === "" && delete obj[k]);
  return obj;
}

export function removeFalseFields(obj: any) {
  Object.keys(obj).forEach((k) => obj[k] === false && delete obj[k]);
  return obj;
}

export const getDeliveryMode = (doorStepPickup: boolean) => {
  if (doorStepPickup) {
    return "Door Step Pickup";
  } else {
    return "Branch Visit";
  }
};

export const getBankFee = (
  role?: string,
  product?: string,
  tentativeAmount?: number,
) => {
  if (role === "customer") {
    if (product === "sendMoneyAbroad") {
      return 300;
    } else if (product === "currencyExchange") {
      return 200;
    } else if (product === "travelCard") {
      return tentativeAmount && tentativeAmount > 200000 ? 0 : 250 || 250;
    }
    return 300;
  }
  if (role === "agent") {
    if (product === "sendMoneyAbroad") {
      return 1500;
    } else if (product === "currencyExchange") {
      return 200;
    } else if (product === "travelCard") {
      return tentativeAmount && tentativeAmount > 200000 ? 0 : 250 || 250;
    }
    return 300;
  }
  if (product === "sendMoneyAbroad") {
    return 300;
  } else if (product === "currencyExchange") {
    return 200;
  } else if (product === "travelCard") {
    return tentativeAmount && tentativeAmount > 200000 ? 0 : 250 || 250;
  }
  return 300;
};

export const getChecklistTypes = (s: any) => {
  if (!s) return;
  const ar = s.replace(" and ", ",").replace(" & ", ",").split(",");
  const res = ar.map((item: string) => item.trim());
  return res;
};

export const calculateForexConversionTax = (tentativeAmount: any) => {
  const constants = {
    GST_PERCENTAGE: 18,
    GST_MIN_TAX_AMOUNT: 250,
    GST_MAX_TAX_AMOUNT: 60000,
  };
  let forexConversionTax = 0;

  if (!tentativeAmount) return forexConversionTax;

  if (tentativeAmount <= 100000) {
    let taxableAmount = tentativeAmount * (1 / 100);
    if (taxableAmount <= constants.GST_MIN_TAX_AMOUNT) {
      forexConversionTax =
        constants.GST_MIN_TAX_AMOUNT * (constants.GST_PERCENTAGE / 100);
    } else {
      forexConversionTax = taxableAmount * (constants.GST_PERCENTAGE / 100);
    }
  }
  if (tentativeAmount > 100000 && tentativeAmount <= 1000000) {
    let taxValuableForSupply = 1000 + (tentativeAmount - 100000) * (0.5 / 100);
    forexConversionTax =
      taxValuableForSupply * (constants.GST_PERCENTAGE / 100);
  }
  if (tentativeAmount > 1000000) {
    let taxValuableForSupply = 5500 + (tentativeAmount - 1000000) * (0.1 / 100);
    if (taxValuableForSupply >= constants.GST_MAX_TAX_AMOUNT) {
      forexConversionTax =
        constants.GST_MAX_TAX_AMOUNT * (constants.GST_PERCENTAGE / 100);
    } else {
      forexConversionTax =
        taxValuableForSupply * (constants.GST_PERCENTAGE / 100);
    }
  }
  forexConversionTax = Math.round(forexConversionTax * 100) / 100;
  return forexConversionTax;
};

export const getCheckListKey = (
  type: "sendMoneyAbroad" | "currencyExchange" | "travelCard",
) => {
  switch (type) {
    case "sendMoneyAbroad":
      return "order";
    case "currencyExchange":
      return "currencyExchange";
    case "travelCard":
      return "travelCard";
  }
};

export const isPurposeEducation = (purpose: string) => {
  if (
    purpose === "smaEducation" ||
    purpose === "smaUniversities" ||
    purpose === "smaGicAccount" ||
    purpose === "smaBlockAccount" ||
    purpose === "smaFlywireAccount" ||
    purpose === "smaWubsAccount" ||
    purpose === "smaAdmissionFee" ||
    purpose === "smaApplicationFee" ||
    purpose === "smaHostelFee" ||
    purpose === "smaStudentVisaFeePayment"
  ) {
    return true;
  } else {
    return false;
  }
};

export const getUserStatus = (status: number) => {
  switch (status) {
    case 1:
      return "Active";
    case 0:
      return "Inactive";
    case 10:
      return "Suspended";
    case 9:
      return "Disabled";
    default:
      return "N/A";
  }
};

export const getProductName = (name: string) => {
  switch (name) {
    case "sendMoneyAbroad":
      return "Send Money Abroad";
    case "currencyExchange":
      return "Currency Exchange";
    case "travelCard":
      return "Travel Card";
    case "sendMoneyAbroad247":
      return "SMA 24*7";
    default:
      return "N/A";
  }
};

export const downloadSingleFile = async (url: string, filename: string) => {
  try {
    let blob = await fetch(url).then((r) => r.blob());
    saveAs(blob, filename);
  } catch (err) {
    toast("Something went wrong. Please Try Again");
  }
};

export const getPageName = (pagePath: string) => {
  let pageNames = Object.keys(PAGE_NAMES);

  let pageName = pageNames.find((item: any) => {
    if (PAGE_NAMES[item] === pagePath) {
      return item;
    }
  });

  const readablePageName = titleCase(
    pageName?.replaceAll("_", " ")?.toLowerCase() || "Unknown",
  );
  // console.log(readablePageName);
  return `BuyExchange - ${readablePageName}`;
};
