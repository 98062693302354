import { createSlice } from "@reduxjs/toolkit";

interface ThemeState {
  isSideNavOpen: boolean;
}

const initialState = { isSideNavOpen: false } as ThemeState;

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    closeSideNav(state) {
      state.isSideNavOpen = false;
    },
    openSideNav(state) {
      state.isSideNavOpen = true;
    },
  },
});

export const { closeSideNav, openSideNav } = themeSlice.actions;
export default themeSlice.reducer;
