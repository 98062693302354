import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

import "react-toastify/dist/ReactToastify.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "rc-tooltip/assets/bootstrap.css";
import "bootstrap/scss/bootstrap.scss";
import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
import "./assets/scss/font-awesome.min.css";
import "./assets/scss/customstyle.scss";
import "./assets/scss/integrationStyle.scss";

import { store } from "./store/store";
import { checkAuthorization } from "./store/slices/user";
import MainRoute from "./route";
import useWindowDimensions from "./utils/useWindowDimensions";
import { useAppSelector } from "./store/hooks";
import { getPageName } from "./utils/functions";

function App() {
  const location = useLocation();
  const { width } = useWindowDimensions();
  const { isSideNavOpen } = useAppSelector((state: any) => state.theme);

  useEffect(() => {
    store.dispatch(checkAuthorization());
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "prod") {
      ReactGA.pageview(
        location.pathname + location.search,
        [],
        getPageName(location?.pathname || ""),
      );
    }
  }, [location]);

  return (
    <div
      className={`sb-nav-fixed main-layout  ${
        isSideNavOpen && width < 992 ? "sb-sidenav-toggled" : ""
      }`}
      // className="sb-nav-fixed main-layout sb-sidenav-toggled"
    >
      <MainRoute />
      <ToastContainer
        autoClose={5000}
        style={{ width: "auto", minWidth: "340px", maxWidth: "450px" }}
        position={toast.POSITION.BOTTOM_RIGHT}
        bodyStyle={{ color: "#756f86" }}
      />
    </div>
  );
}

export default App;
