enum API {
  LOGIN = "/auth/login",
  SIGNUP = "/auth/signup",
  LOGOUT = "/auth/logout",
  FORGOT_PASSWORD = "/auth/forgot-password",
  RESET_PASSWORD = "/auth/reset-password",
  VALIDATE_RESET_PASSWORD = "/auth/reset-password/validate",
  REFRESH_TOKEN = "/auth/token",
  PROFILE = "/user/profile",
  GET_ORDER_LOG_FILE_URL = "/storage/file/preview",

  CUSTOMER_GET_ORDER_LIST = "/customer/order",
  CUSTOMER_GET_ORDER_DETAILS = "/customer/order",
  CUSTOMER_GET_TRANSFER_DETAILS = "/money-transfer247",
  CUSTOMER_POST_ORDER_LOG = "/customer/order/log",
  CUSTOMER_GET_ORDER_LOG = "customer/order/log",
  CUSTOMER_GET_RECEIVER_LIST = "/customer/receiver",
  CUSTOMER_SELECT_RECEIVER = "/customer/receiver/select",
  CUSTOMER_GET_RECENT_SENDER = "/customer/sender/recent",
  CUSTOMER_RECENT_EXCHANGES = "/customer/currency-exchange/recent",
  CUSTOMER_CREATE_RECEIVER = "customer/create-receiver",
  CUSTOMER_GET_RATE_ALERTS = "/customer/rate-alert",
  CUSTOMER_CREATE_RATE_ALERT = "/customer/rate-alert",
  CUSTOMER_UPDATE_RATE_ALERT = "/customer/rate-alert",
  CUSTOMER_RECENT_CUSTOMER = "/customer/recent-customer",

  AGENT_DASHBOARD = "/agent/dashboard/overview",
  AGENT_SAVE_PROFILE = "/agent/settings/profile",
  AGENT_GET_RECENT_SENDER = "/agent/sender/recent",
  AGENT_PROFILE_KYC_UPLOAD = "/upload/kyc-document",
  AGENT_PROFILE_KYC_APPROVE = "/kyc/approval-request/",
  AGENT_GET_ORDER_LIST = "/agent/order",
  AGENT_GET_SMA_ORDER_DETAILS = "/agent/order",
  AGENT_GET_CE_TC_ORDER_DETAILS = "/agent/order",
  AGENT_POST_ORDER_LOG = "/agent/order/log",
  AGENT_EDIT_ORDER_LOG = "/agent/order/log/:id",
  AGENT_GET_ORDER_LOG = "agent/order/log",
  AGENT_GET_RECEIVER_LIST = "/agent/receiver",
  AGENT_SELECT_RECEIVER = "/agent/receiver/select",
  AGENT_CREATE_RECEIVER = "agent/create-receiver",
  AGENT_RECENT_CUSTOMER = "/agent/recent-customer",
  AGENT_CURRENCIES = "/form-value/currencies",
  
  AGENTADMIN_USER="/agent-admin/user",
  GET_BRANCH_BY_ID="/agent-admin/user/:id",
  Edit_BRANCH_BY_ID="/agent-admin/user/:id",
  DELETE_BRANCH_BY_ID="/agent-admin/user/:id",

  AGENTADMIN_FX_RATES_LIST="/agent-admin/fx-rate",
  EDIT_FX_RATES_LIST="/agent-admin/fx-rate",

  ADMIN_FORM_VALUES = "/form-value/admin/report",
  AGENT_FORM_VALUES = "/form-value/admin/partner-allocation/agents",
  ADMIN_GET_USER_LIST = "/admin/user",
  ADMIN_GET_OTP_USER_LIST = "/admin/user",
  ADMIN_GET_USER_DETAILS = "/admin/user",
  ADMIN_UPDATE_CUSTOMER_PROFILE = "/admin/user/customer",
  ADMIN_UPDATE_AGENT_PROFILE = "/admin/user/agent",
  ADMIN_UPDATE_CHANNEL_PARTNER_PROFILE = "/admin/user/channel-partner",
  ADMIN_CPGROUP_FORM_VALUES = "/form-value/admin/channel-partner-group",
  ADMIN_PARTNER_ALLOCATION_C_PARTNERS = "/form-value/admin/partner-allocation/channel-partners",
  ADMIN_PURPOSE_FORM_VALUES = "/form-value/admin/partner-allocation",
  ADMIN_USER_KYC_UPLOAD = "/admin/user/:userId/document",
  ADMIN_ENABLE_USER = "/admin/user/:userId/enable",
  ADMIN_DISABLE_USER = "/admin/user/:userId/disable",
  ADMIN_ARCHIVE_USER = "/admin/user/:userId/archive",
  ADMIN_NEW_USER_LIST = "/admin/user/approval-request",
  ADMIN_ARCHIVED_USER_LIST = "/admin/user/archived",
  ADMIN_APPROVE_USER = "/admin/user/:userId/approve",
  ADMIN_DECLINE_USER = "/admin/user/:userId/decline",
  ADMIN_EXCHANGE_RATE_LIST = "/admin/exchange-rate",
  ADMIN_CREATE_EXCHANGE_RATE = "/admin/exchange-rate",
  ADMIN_EXCHANGE_RATE_DETAILS = "/admin/exchange-rate",
  ADMIN_UPDATE_EXCHANGE_RATE = "/admin/exchange-rate/:exchangeRateId",
  ADMIN_ENABLE_EXCHANGE_RATE = "/admin/exchange-rate/:exchangeRateId/enable",
  ADMIN_DISABLE_EXCHANGE_RATE = "/admin/exchange-rate/:exchangeRateId/disable",
  ADMIN_GET_REPORT_CSV = "/admin/report/csv",
  ADMIN_REPORT_OVERVIEW = "/admin/report",
  ADMIN_GET_ORDER_LIST = "/admin/order",
  ADMIN_GET_SMA_ORDER_DETAILS = "/admin/order",
  ADMIN_GET_CE_TC_ORDER_DETAILS = "/admin/order",
  ADMIN_UPDATE_ORDER_STATUS = "/admin/order",
  ADMIN_POST_ORDER_LOG = "/admin/order/log",
  ADMIN_EDIT_ORDER_LOG = "/admin/order/log/:id",
  ADMIN_GET_ORDER_LOG = "admin/order/log",
  ADMIN_CREATE_ADMIN = "/admin/user/admin",
  MARKER_CHECKER_POST = "/admin/order/:id/marker-checker",
  MARKER_CHECKER_PUT = "/admin/order/:id/marker-checker",
  ADMIN_UPDATE_PROFILE = "/admin/settings/profile",
  ADMIN_CHANNEL_PARTNER_GROUP = "/admin/channel-partner-group",
  ADMIN_PARTNER_BANK_ALLOCATION = "/admin/partner-bank-allocation",
  ADMIN_AGENT_PARTNER_BANK_ALLOCATION = "/admin/partner-bank-allocation/agent",
  // EDIT_ADMIN_AGENT_PARTNER_BANK_ALLOCATION = "/admin/partner-bank-allocation/agent/:id",
  ADMIN_RATE_CARD_FORM_VALUES = "/form-value/admin/rate-card",
  ADMIN_AGENT_FX_RATE_LIST = "/admin/agent-fx-rate/:agentAdminId/list",
  ADMIN_GET_CURRENCIES = "/form-value/currencies",
  ADMIN_AGENT_FX_RATE_EDIT = "admin/agent-fx-rate",

  CHANNEL_PARTNER_FORM_VALUES = "/form-value/channel-partner/settlement-rate",
  CHANNEL_PARTNER_DASHBOARD = "/channel-partner/dashboard/overview",
  CHANNEL_PARTNER_SAVE_PROFILE = "/channel-partner/settings/profile",
  CHANNEL_PARTNER_GET_ORDER_LIST = "/channel-partner/order",
  CHANNEL_PARTNER_GET_SMA_ORDER_DETAILS = "/channel-partner/order",
  CHANNEL_PARTNER_GET_CE_TC_ORDER_DETAILS = "/channel-partner/order",
  CHANNEL_PARTNER_POST_ORDER_LOG = "/channel-partner/order/log",
  CHANNEL_PARTNER_EDIT_ORDER_LOG = "/channel-partner/order/log/:id",
  CHANNEL_PARTNER_GET_ORDER_LOG = "/channel-partner/order/log",
  CHANNEL_PARTNER_USER_KYC_UPLOAD = "/upload/kyc-document",
  CHANNEL_PARTNER_UPDATE_ORDER_STATUS = "/channel-partner/order",
  CHANNEL_PARTNER_GET_CURRENCY_LIST = "/channel-partner/currency",
  CHANNEL_PARTNER_GET_CURRENCY_DETAILS = "/channel-partner/currency",
  CHANNEL_PARTNER_CREATE_CURRENCY = "/channel-partner/currency",
  CHANNEL_PARTNER_UPDATE_CURRENCY = "/channel-partner/currency",
  CHANNEL_PARTNER_ENABLE_CURRENCY = "/channel-partner/currency/:currencyId/availability",
  CHANNEL_PARTNER_DISABLE_CURRENCY = "/channel-partner/currency/:currencyId/availability",
  CHANNEL_PARTNER_DELETE_CURRENCY = "/channel-partner/currency",
  CHANNEL_PARTNER_CPGROUP_FORM_VALUES = "/form-value/channel-partner/channel-partner-group",
  CHANNEL_PARTNER_AVG_SETTLEMENT_RATE = "/form-value/channel-partner/average-settlement-rate",

  CHANGE_PASSWORD = "/user/change-password",
  NOTIFICATIONS = "/notification",

  // 24 7 sma
  CALCULATE_MONEY = "money-transfer247/calculate",
  
}

const publicEndpoints = [
  API.FORGOT_PASSWORD,
  API.LOGIN,
  API.RESET_PASSWORD,
  API.LOGOUT,
  API.REFRESH_TOKEN,
  API.RESET_PASSWORD,
  API.SIGNUP,
  API.VALIDATE_RESET_PASSWORD,
];

export { publicEndpoints };

export default API;
