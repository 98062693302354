import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const TableRow: React.FC<any> = ({
  row,
  from,
  editMode,
  editedRows,
  setEditedRows,
  index
}: {
  row: any;
  from: any;
  editMode: boolean;
  editedRows: any[];
  setEditedRows: any;
  index:any
}) => {

  const handleInputChange =  (
    index: number,
    columnName: string,
    value: any,
    _id: any
  ) => {
    const updatedFormValues = [...editedRows];
    updatedFormValues[index] = {
      _id: _id,
      ...updatedFormValues[index],
      [columnName]: value,
    };
    setEditedRows(updatedFormValues);
  };
const checkinput=(index:any,value:any,id:any,name:any)=>{
  if(value>4){
    toast.error("Maximum margin can be added 4 Rupees")
  }else{
    handleInputChange(
      index,
      name,
      value,
      id
    )
  }
}
  return (
    <>
      <tr>
        <td>{row?.currency?.name || "N/A"}</td>
        <td>{row?.buyExEducationSmaRate ?? "N/A"}</td>
        <td>{ row?.buyExOtherSmaRate ?? "N/A"}</td>
        <td>{ row?.buyExTcRate ?? "N/A"}</td>
        <td>
          {editMode ? (
            <input
              type="text"
              name="educationSmaMargin"
              value={
                editedRows[index]?.educationSmaMargin !== undefined
                  ? editedRows[index]?.educationSmaMargin
                  : row?.educationSmaMargin
              }
              onChange={(e) =>
                // handleInputChange(
                //   index,
                //   "educationSmaMargin",
                //   e.target.value,
                //   row?._id
                // )
              checkinput(index,e.target.value,row?._id,"educationSmaMargin")

              }
              style={{
                width: '40px',          
                height: '40px',         
                fontSize: '16px',       
                padding: '8px',        
                textAlign: 'center',    
                
                border: '1px solid #ccc',  
                borderRadius: '4px',    
                outline: 'none',       
              }}
            />
         ) : (
            row?.educationSmaMargin ?? "N/A"
          )}
        </td>
        <td>
          {editMode ? (
            <input
            type="text"
            name="otherSmaMargin"
            value={
              editedRows[index]?.otherSmaMargin !== undefined
                ? editedRows[index]?.otherSmaMargin
                : row?.otherSmaMargin
            }
            onChange={(e) =>
              // handleInputChange(
              //   index,
              //   "otherSmaMargin",
              //   e.target.value,
              //   row?._id
              // )
              checkinput(index,e.target.value,row?._id,"otherSmaMargin")
            }
            style={{
              width: '40px',          
              height: '40px',         
              fontSize: '16px',       
              padding: '8px',         
              textAlign: 'center',    
              
              border: '1px solid #ccc', 
              borderRadius: '4px',   
              outline: 'none', 
            }}
          />
          ) : (
            row?.otherSmaMargin ?? "N/A"
          )}
        </td>
        <td>
          {editMode ? (
            <input
              type="text"
              name="tcMargin"
              value={
                editedRows[index]?.tcMargin !== undefined
                  ? editedRows[index]?.tcMargin
                  : row?.tcMargin
              }
              onChange={(e) =>
                checkinput(index,e.target.value,row?._id,"tcMargin")
              }
              style={{
                width: '40px',         
                height: '40px',         
                fontSize: '16px',      
                padding: '8px',         
                textAlign: 'center',   
                
                border: '1px solid #ccc', 
                borderRadius: '4px',   
                outline: 'none', 
              }}
            />
          ) : (
            row?.tcMargin ?? "N/A"
          )}
        </td>
        <td></td>
      </tr>
    </>
  );
};

export default React.memo(TableRow);
