import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import history from "./utils/history";
import { store } from "./store/store";
import ErrorBoundary from "./pages/common/ErrorBoundary";
import { setupInterceptors } from "./utils/service";
import CustomRouter from "./utils/customRouter";
import "./index.css";

setupInterceptors(store);

if (process.env.REACT_APP_ENV === "prod") {
  // For Google Analytics
  ReactGA.initialize("G-GPYFGSR5K7");

  //For Google Tag Manager
  const tagManagerArgs = {
    gtmId: "GTM-K2564JW",
  };
  TagManager.initialize(tagManagerArgs);

  //For Facebook Pixel
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init("822745638873060", options as any);
  ReactPixel.pageView();
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <CustomRouter history={history}>
          <App />
        </CustomRouter>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
