import React, { useState } from "react";

import searchIcon from "../../../assets/img/Icon-search.svg";
import UserTypes from "../../../config/userTypes";
import useManageFxRate from "./useManageFxRate";
import AgentManageFxRateTable from "../../../components/agent/manageFxRateTable";

const AgentManageFxRate: React.FC = () => {
  const {
    query,
    fxRateList,
    paginationRange,
    page,
    sort,
    filter,
    total,
    pageSize,
    isLoading,
    setIsLoading,
    searchParams,
    urlParamsHandler,
    handleSaveClick,
    editedRows,
    setEditedRows,
    editMode,
    setEditMode
  } = useManageFxRate();
  
  return (
<>
      <div className="inner-box-header">
        <nav aria-label="breadcrumb" className="c-breadcrumb">
          <ol className="breadcrumb">
            <li>
              <i className="fa fa-home" aria-hidden="true"></i>
            </li>
            <li className="breadcrumb-item">
              <a href="#">Agent User</a>
            </li>
            <li className="breadcrumb-item active">
              <a href="#">Manage FX Rate</a>
            </li>
          </ol>
        </nav>
        <h3>Manage FX Rate</h3>
        <p>Fix your Customer rate</p>
      </div>
      {/* <div className="section-header">
        <div className="right-filter btn-group btn-group-float-r">
          
          
          <div className="input-group-append">
          <button className="btn btn-primary-g mr-1" type="button" onClick={()=>setEditMode(!editMode)}>{editMode?"Cancel Edit":"Edit"}</button>
          <button className="btn btn-primary-1 mr-1" style={{backgroundColor: '#0880AE',color: '#FFFFFF',border: '#0880AE'}} type="button" onClick={handleSaveClick}>Save</button>
        </div>
        </div>
        
      </div> */}
       <div className="section-header">
        <div className="right-filter btn-group btn-group-float-r">
          <div className="input-group-append mr-2">
          <button className="btn btn-primary-g mr-1" type="button" onClick={()=>setEditMode(!editMode)}>{editMode?"Cancel Edit":"Edit"}</button>
            <button className="btn btn-lg " style={{backgroundColor: '#0880AE',color: '#FFFFFF',border: '#0880AE'}} type="submit" onClick={handleSaveClick}
           >
              Save
            </button>
          </div>
          
         
        </div>
        {/* <h1> results</h1> */}
      </div>
      <section className="mr-20">
        <div className="table-responsive table-seperate">
          <AgentManageFxRateTable
            data={fxRateList || []}
            sort={JSON.parse(sort || `{}`)}
            filter={JSON.parse(filter || `{}`)}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            editMode={editMode}
            editedRows={editedRows}
            setEditedRows={setEditedRows}
          />
          

        </div>
      </section>
      <div className="c-pagination">
        <div>
          <p>
            {page === 1 ? 1 : pageSize * (page - 1) + 1} to{" "}
            {pageSize * page < total ? pageSize * page : total} of {total}
          </p>
        </div>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <p
                className="page-link"
                aria-label="Previous"
                onClick={
                  page > 1
                    ? () => urlParamsHandler("page", Number(page - 1))
                    : () => {}
                }>
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>
              </p>
            </li>

            {paginationRange?.map((p: any) => (
              <li
                className={`page-item ${page === p && "active"}`}
                onClick={() => {
                  p !== "..." && urlParamsHandler("page", Number(p));
                }}>
                <p className="page-link">{p}</p>
              </li>
            ))}

            <li className="page-item">
              <p
                className="page-link"
                onClick={
                  page < Math.ceil(total / pageSize)
                    ? () => urlParamsHandler("page", Number(page + 1))
                    : () => {}
                }
                aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </p>
            </li>
          </ul>
        </nav>
        <div>
          <button
            disabled={page > 1 ? false : true}
            className={`btn btn-md mx-1 ${
              page > 1 ? "btn-primary-g" : "btn-primary-b-g"
            }`}
            onClick={
              page > 1
                ? () => urlParamsHandler("page", Number(page - 1))
                : () => {}
            }>
            Previous
          </button>
          <button
            disabled={page < Math.ceil(total / pageSize) ? false : true}
            className={`btn  btn-md ${
              page < Math.ceil(total / pageSize)
                ? "btn-primary-g"
                : "btn-primary-b-g"
            }`}
            onClick={
              page < Math.ceil(total / pageSize)
                ? () => urlParamsHandler("page", Number(page + 1))
                : () => {}
            }>
            Next
          </button>
        </div>
      </div>
</>
  );
};

export default AgentManageFxRate;
