import { logout } from "./../store/slices/user";
import { store } from "../store/store";
import service from "../utils/service";
import API from "../config/api";
import axios from "axios";
import { toast } from "react-toastify";

const AuthService = {
  login: async (params: any = {}) => {
    try {
      const res = await service.post(API.LOGIN, params);
      if (res?.data?.data) {
        localStorage.setItem(
          "auth-access-token",
          res?.headers["auth-access-token"],
        );
        localStorage.setItem(
          "auth-refresh-token",
          res?.headers["auth-refresh-token"],
        );
        localStorage.setItem("user", JSON.stringify(res?.data?.data));
      }
      return res.data;
    } catch (error) {
      return error;
    }
  },
  register: async (params: any = {}) => {
    try {
      const res = await service.post(API.SIGNUP, params);
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  },
  logout: async () => {
    try {
      const res = await service.post(API.LOGOUT);
      if (res?.data) {
        store.dispatch(logout());
        return res.data;
      }
    } catch (error) {
      return error;
    }
  },
  forgotPassword: async (params: any = {}) => {
    try {
      const res = await service.post(API.FORGOT_PASSWORD, params);
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  },
  resetPassword: async (params: any = {}, token: string) => {
    service.interceptors.request.use(
      async (config: any) => {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error: any) => {
        Promise.reject(error);
      },
    );
    try {
      const res = await service.post(API.RESET_PASSWORD, params);
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  },
  validateResetPassword: async (token: string) => {
    service.interceptors.request.use(
      async (config: any) => {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error: any) => {
        Promise.reject(error);
      },
    );
    try {
      const res = await service.post(API.VALIDATE_RESET_PASSWORD);
      if (res?.data) {
        return res.data;
      }
    } catch (error) {
      return error;
    }
  },
  refreshToken: async () => {
    try {
      const token = localStorage.getItem("auth-refresh-token");
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${API.REFRESH_TOKEN}`,
        undefined,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (res?.data?.statusCode === 200) {
        localStorage.setItem(
          "auth-access-token",
          await res?.headers["auth-access-token"],
        );
        localStorage.setItem(
          "auth-refresh-token",
          await res?.headers["auth-refresh-token"],
        );
        service.defaults.headers.common["Authorization"] =
          "Bearer " + res?.headers["auth-access-token"];
      }
      return res.data;
    } catch (error) {
      return error;
    }
  },
  getProfile: async () => {
    try {
      const res = await service.get(API.PROFILE);
      if (res?.data) {
        return res.data;
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      return error;
    }
  },
  resendOtp: async (params: any = {}) => {
    try {
      const res = await service.post("/auth/signup/resend-otp", params);
      if (res?.data.statusCode === 200) {
        toast.success(res?.data?.message || "OTP resend successfully");
      }
      return res.data;
    } catch (error) {
      //console.log(error);
    }
  },
  verifyOtp: async (params: any = {}) => {
    try {
      const res = await service.post("/auth/signup/verify-otp", params);
      if (res?.data.statusCode === 200) {
        toast.success(res?.data?.message);
      }
      return res.data;
    } catch (error) {
      //console.log(error);
    }
  },
};

export default AuthService;
