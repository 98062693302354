import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";

import AdminService from "../../../services/admin.service";
import { usePagination } from "../../../utils/usePagination";
import useUrlParams from "../../../utils/useUrlParams";
import AgentService from "../../../services/agent.service";

const useManageFxRate = () => {
  const { searchParams, urlParamsHandler } = useUrlParams();
  const [editedRows, setEditedRows] = useState<any[]>([]);
  const [editMode, setEditMode] = useState(false);
  const page = parseInt(searchParams.get("page") || "1");
  const query = searchParams.get("search") || "";
  const sort =
    searchParams.get("sort") || JSON.stringify({ createdAt: "desc" });
  const filter =
    searchParams.get("filter") || JSON.stringify({ role: "customer" });

  const [fxRateList, setFxRateList] = useState<any>();
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const pageSize = 10;

  const paginationRange = usePagination({
    currentPage: page,
    totalCount: total,
    siblingCount: 1,
    pageSize,
  });

  const getFXRateList = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await AgentService.getFXRateList(page, query, filter, sort);
      if ((await res?.statusCode) === 200) {
        if ((await res?.data?.total) === 0) {
          const res1 = await AgentService.getCuurencyList();
          const convertedData = {
            rates: convertToRatesFormat(res1.data),
          };
          setFxRateList(await convertedData?.rates);
          // setTotal(await convertedData?.rates?.length);
        } else {
          setFxRateList(await res?.data?.rates);
          // setTotal(await res?.data?.total);
        }
      }
    } catch (error) {
      setFxRateList([]);
      setTotal(0);
    }
    setIsLoading(false);
  }, [page, sort, filter, query]);

  const mergeFormValuesWithData = (originalData: any, formValues: any) => {
    const filteredArray = formValues.filter((item: any) => item && item._id);
    return originalData.map((dataItem: any) => {
      const matchingFormValue = filteredArray.find((fv: any) => {
        return fv._id === dataItem._id;
      });

      return matchingFormValue
        ? { ...dataItem, ...matchingFormValue }
        : dataItem;
    });
  };

  const handleSaveClick = async () => {
    const mergedData = await mergeFormValuesWithData(fxRateList, editedRows);
    const desiredStructure = mergedData.map((entry: any) => {
      const currencyCode = entry.currency.code;
      const educationSmaMargin = parseFloat(entry.educationSmaMargin);
      const otherSmaMargin = parseFloat(entry.otherSmaMargin);
      const tcMargin = parseFloat(entry.tcMargin);
      return {
        currencyCode: currencyCode,
        educationSmaMargin: educationSmaMargin,
        otherSmaMargin: otherSmaMargin,
        tcMargin: tcMargin,
      };
    });

    const res = await AgentService.editFxRate(desiredStructure);
    if (res.statusCode == 200) {
      toast.success(res.message);
      getFXRateList();
      setEditMode(false);
    } else {
      // toast.error("Something went wrong");
      toast.error(res.message);

    }
  };

  const convertToRatesFormat = (currencies: []) => {
    return currencies?.map(
      (currency: { _id: any; name: any; code: any; conversionRate: any }) => ({
        currency: {
          name: currency.name,
          code: currency.code,
          conversionRate: currency.conversionRate,
        },
        buyExEducationSmaRate: currency.conversionRate,
        buyExOtherSmaRate: currency.conversionRate,
        buyExTcRate: currency.conversionRate,
        educationSmaMargin: 0,
        otherSmaMargin: 0,
        tcMargin: 0,

        _id: currency._id,
      })
    );
  };
  useEffect(() => {
    getFXRateList();
  }, [searchParams]);
  return {
    paginationRange,
    query,
    filter,
    sort,
    fxRateList,
    page,
    total,
    pageSize,
    isLoading,
    setIsLoading,
    setTotal,
    searchParams,
    urlParamsHandler,
    handleSaveClick,
    editedRows,
    setEditedRows,
    setEditMode,
    editMode,
  };
};
export default useManageFxRate;
