const PAGE_NAMES: any = {
  // PUBLIC
  HOME: "/home",
  PRIVACY_POLICY: "privacy-policy",
  FAQ: "faq",
  TRADE_FINANCE_DESK: "http://www.siesta.money/",
  SEND_MONEY_ABROAD_MAIN: "/send-money-abroad",
  SEND_MONEY_ABROAD_SENDER_DETAILS: "/send-money-abroad/sender-details",
  SEND_MONEY_ABROAD_RECEIVER_DETAILS: "/send-money-abroad/receiver-details",
  SEND_MONEY_ABROAD_CHECKLIST: "/send-money-abroad/checklist",
  SEND_MONEY_ABROAD_ORDER_REVIEW: "/send-money-abroad/order-review",
  SEND_MONEY_ABROAD_PAYMENT_MODE: "/send-money-abroad/payment-mode",
  SEND_MONEY_ABROAD_ORDER_RECEIVED: "/send-money-abroad/order-received",
  CURRENCY_EXCHANGE_MAIN: "/currency-exchange",
  CURRENCY_EXCHANGE_SELECT_LOCATION: "/currency-exchange/select-location",
  CURRENCY_EXCHANGE_CUSTOMER_DETAILS: "/currency-exchange/customer-details",
  CURRENCY_EXCHANGE_CHECKLIST: "/currency-exchange/checklist",
  CURRENCY_EXCHANGE_ORDER_REVIEW: "/currency-exchange/order-review",
  CURRENCY_EXCHANGE_PAYMENT_MODE: "/currency-exchange/payment-mode",
  CURRENCY_EXCHANGE_ORDER_RECEIVED: "/currency-exchange/order-received",
  TRAVEL_CARD_MAIN: "/travel-card",
  TRAVEL_CARD_SELECT_LOCATION: "/travel-card/select-location",
  TRAVEL_CARD_CUSTOMER_DETAILS: "/travel-card/customer-details",
  TRAVEL_CARD_CHECKLIST: "/travel-card/checklist",
  TRAVEL_CARD_ORDER_REVIEW: "/travel-card/order-review",
  TRAVEL_CARD_PAYMENT_MODE: "/travel-card/payment-mode",
  TRAVEL_CARD_ORDER_RECEIVED: "/travel-card/order-received",

  // AUTH
  MAIN: "/auth",
  LOGIN: "/auth/login",
  REGISTER: "/auth/register",
  FORGOT_PASSWORD: "/auth/forgot-password",
  RESET_PASSWORD: "/auth/reset-password",
  OTP: "/auth/otp",

  //PRIVATE
  PRIVATE_MAIN: "/app/:userType",
  PRIVATE_DASHBOARD: "dashboard",

  // ADMIN
  ADMIN_INDEX: "/app/admin",
  ADMIN_DASHBOARD: "dashboard",
  ADMIN_MANAGE_USERS_MAIN: "manage-users",
  ADMIN_MANAGE_USERS_EDIT_USER: "manage-users/edit/:userId",
  ADMIN_NEW_USER_MAIN: "new-user",
  ADMIN_NEW_USER_DETAILS: "new-user/:userId",
  ADMIN_ARCHIVED_USERS: "archived-users",
  ADMIN_MANAGE_EXCHANGE_RATES: "manage-exchange-rates",
  ADMIN_MANAGE_REPORTS: "manage-reports",
  ADMIN_MANAGE_ORDERS: "manage-orders",
  ADMIN_MASTER_TABLES: "master-tables",
  ADMIN_PARTNER_BANK_ALLOCATION: "partner-bank-allocation",
  ADMIN_CREATE_ADMIN_USERS: "create-admin-users",
  ADMIN_SETTINGS_MAIN: "settings",
  ADMIN_SETTINGS_MY_PROFILE: "settings/my-profile",
  ADMIN_SETTINGS_CHANGE_PASSWORD: "settings/change-password",
  ADMIN_SETTINGS_CHANNEL_PARTNER_GROUP: "settings/channel-partner-group",

  // STAFF
  STAFF_INDEX: "/app/staff",
  STAFF_DASHBOARD: "dashboard",
  STAFF_MANAGE_USERS_MAIN: "manage-users",
  STAFF_MANAGE_USERS_EDIT_USER: "manage-users/edit/:userId",
  STAFF_MANAGE_ORDERS: "manage-orders",
  STAFF_SETTINGS_MAIN: "settings",
  STAFF_SETTINGS_MY_PROFILE: "settings/my-profile",
  STAFF_SETTINGS_CHANGE_PASSWORD: "settings/change-password",

  // CUSTOMER
  CUSTOMER_DASHBOARD: "/app/customer/dashboard",
  CUSTOMER_INDEX: "/app/customer",
  CUSTOMER_SEND_MONEY_ABROAD_MAIN: "/app/customer/send-money-abroad",
  CUSTOMER_SEND_MONEY_ABROAD_ALL_RECEIVERS:
    "/app/customer/send-money-abroad/all-receivers",
  CUSTOMER_SEND_MONEY_ABROAD_NEW_FUND_TRANSFER_MAIN:
    "/app/customer/send-money-abroad/new-fund-transfer",
  CUSTOMER_SEND_MONEY_ABROAD_NEW_FUND_TRANSFER_SELECT_RECEIVER_LIST:
    "/app/customer/send-money-abroad/new-fund-transfer/select-receiver",
  CUSTOMER_SEND_MONEY_ABROAD_NEW_FUND_TRANSFER_SELECT_RECEIVER_TYPE:
    "/app/customer/send-money-abroad/new-fund-transfer/select-receiver-type",
  CUSTOMER_SEND_MONEY_ABROAD_NEW_FUND_TRANSFER_SENDER_DETAILS:
    "/app/customer/send-money-abroad/new-fund-transfer/sender-details",
  CUSTOMER_SEND_MONEY_ABROAD_NEW_FUND_TRANSFER_RECEIVER_DETAILS:
    "/app/customer/send-money-abroad/new-fund-transfer/receiver-details",
  CUSTOMER_SEND_MONEY_ABROAD_NEW_FUND_TRANSFER_CHECKLIST:
    "/app/customer/send-money-abroad/new-fund-transfer/checklist",
  CUSTOMER_SEND_MONEY_ABROAD_NEW_FUND_TRANSFER_ORDER_REVIEW:
    "/app/customer/send-money-abroad/new-fund-transfer/order-review",
  CUSTOMER_SEND_MONEY_ABROAD_NEW_FUND_TRANSFER_PAYMENT_MODE:
    "/app/customer/send-money-abroad/new-fund-transfer/payment-mode",
  CUSTOMER_SEND_MONEY_ABROAD_NEW_FUND_TRANSFER_ORDER_RECEIVED:
    "/app/customer/send-money-abroad/new-fund-transfer/order-received",
  CUSTOMER_CREATE_RECEIVER: "/app/customer/send-money-abroad/create-receiver",
  CUSTOMER_CURRENCY_EXCHANGE_MAIN: "/app/customer/currency-exchange",
  CUSTOMER_CURRENCY_EXCHANGE_RECENT_EXCHANGES:
    "currency-exchange/recent-exchanges",
  CUSTOMER_CURRENCY_EXCHANGE_SELECT_PURPOSE_CURRENCY:
    "currency-exchange/select-purpose-currency",
  CUSTOMER_CURRENCY_EXCHANGE_SELECT_LOCATION:
    "currency-exchange/select-location",
  CUSTOMER_CURRENCY_EXCHANGE_CUSTOMER_DETAILS:
    "currency-exchange/customer-details",
  CUSTOMER_CURRENCY_EXCHANGE_CHECKLIST:
    "/app/customer/currency-exchange/checklist",
  CUSTOMER_CURRENCY_EXCHANGE_ORDER_REVIEW:
    "/app/customer/currency-exchange/order-review",
  CUSTOMER_CURRENCY_EXCHANGE_PAYMENT_MODE:
    "/app/customer/currency-exchange/payment-mode",
  CUSTOMER_CURRENCY_EXCHANGE_ORDER_RECEIVED:
    "/app/customer/currency-exchange/order-received",
  CUSTOMER_TRAVEL_CARD_MAIN: "/app/customer/travel-card",
  CUSTOMER_TRAVEL_CARD_SELECT_LOCATION:
    "/app/customer/travel-card/select-location",
  CUSTOMER_TRAVEL_CARD_CUSTOMER_DETAILS:
    "/app/customer/travel-card/customer-details",
  CUSTOMER_TRAVEL_CARD_CHECKLIST: "/app/customer/travel-card/checklist",
  CUSTOMER_TRAVEL_CARD_ORDER_REVIEW: "/app/customer/travel-card/order-review",
  CUSTOMER_TRAVEL_CARD_PAYMENT_MODE: "/app/customer/travel-card/payment-mode",
  CUSTOMER_TRAVEL_CARD_ORDER_RECEIVED:
    "/app/customer/travel-card/order-received",
  CUSTOMER_RATE_ALERTS: "/app/customer/rate-alerts",
  CUSTOMER_SETTINGS_MAIN: "/app/customer/settings",
  CUSTOMER_SETTINGS_CHANGE_PASSWORD: "/app/customer/settings/change-password",
  CUSTOMER_NOTIFICATIONS: "/app/customer/notifications",

  // AGENT
  AGENT_DASHBOARD: "/app/agent/dashboard",
  AGENT_INDEX: "/app/agent",
  AGENT_MANAGE_ORDERS: "/app/agent/manage-orders",
  AGENT_SETTINGS_MAIN: "/app/agent/settings",
  AGENT_SETTINGS_AGENT_PROFILE: "/app/agent/settings/agent-profile",
  AGENT_SETTINGS_AFFILIATE_LINKS: "/app/agent/settings/affiliate-links",
  AGENT_SETTINGS_TERMS_AND_CONDITIONS:
    "/app/agent/settings/terms-and-conditions",
  AGENT_SETTINGS_CHANGE_PASSWORD: "/app/agent/settings/change-password",
  AGENT_NOTIFICATIONS: "/app/agent/notifications",
  AGENT_PLACE_AN_ORDER_MAIN: "/app/agent/place-an-order",
  AGENT_PLACE_AN_ORDER_SEND_MONEY_ABROAD_MAIN:
    "/app/agent/place-an-order/send-money-abroad",
  AGENT_PLACE_AN_ORDER_SEND_MONEY_ABROAD_ALL_RECEIVERS:
    "/app/agent/place-an-order/send-money-abroad/all-receivers",
  AGENT_PLACE_AN_ORDER_SEND_MONEY_ABROAD_CREATE_RECEIVER:
    "/app/agent/place-an-order/send-money-abroad/create-receiver",
  AGENT_PLACE_AN_ORDER_SEND_MONEY_ABROAD_NEW_FUND_TRANSFER_MAIN:
    "/app/agent/place-an-order/send-money-abroad/new-fund-transfer",
  AGENT_PLACE_AN_ORDER_SEND_MONEY_ABROAD_NEW_FUND_TRANSFER_SELECT_RECEIVER_LIST:
    "/app/agent/place-an-order/send-money-abroad/new-fund-transfer/select-receiver",
  AGENT_PLACE_AN_ORDER_SEND_MONEY_ABROAD_NEW_FUND_TRANSFER_SELECT_RECEIVER_TYPE:
    "/app/agent/place-an-order/send-money-abroad/new-fund-transfer/select-receiver-type",
  AGENT_PLACE_AN_ORDER_SEND_MONEY_ABROAD_NEW_FUND_TRANSFER_PURPOSE_CURRENCY:
    "/app/agent/place-an-order/send-money-abroad/new-fund-transfer/purpose-currency",
  AGENT_PLACE_AN_ORDER_SEND_MONEY_ABROAD_NEW_FUND_TRANSFER_SENDER_DETAILS:
    "/app/agent/place-an-order/send-money-abroad/new-fund-transfer/sender-details",
  AGENT_PLACE_AN_ORDER_SEND_MONEY_ABROAD_NEW_FUND_TRANSFER_RECEIVER_DETAILS:
    "/app/agent/place-an-order/send-money-abroad/new-fund-transfer/receiver-details",
  AGENT_PLACE_AN_ORDER_SEND_MONEY_ABROAD_NEW_FUND_TRANSFER_CHECKLIST:
    "/app/agent/place-an-order/send-money-abroad/new-fund-transfer/checklist",
  AGENT_PLACE_AN_ORDER_SEND_MONEY_ABROAD_NEW_FUND_TRANSFER_ORDER_REVIEW:
    "/app/agent/place-an-order/send-money-abroad/new-fund-transfer/order-review",
  AGENT_PLACE_AN_ORDER_SEND_MONEY_ABROAD_NEW_FUND_TRANSFER_PAYMENT_MODE:
    "/app/agent/place-an-order/send-money-abroad/new-fund-transfer/payment-mode",
  AGENT_PLACE_AN_ORDER_SEND_MONEY_ABROAD_NEW_FUND_TRANSFER_ORDER_RECEIVED:
    "/app/agent/place-an-order/send-money-abroad/new-fund-transfer/order-received",
  AGENT_PLACE_AN_ORDER_CURRENCY_EXCHANGE_MAIN:
    "/app/agent/place-an-order/currency-exchange",
  AGENT_PLACE_AN_ORDER_CURRENCY_EXCHANGE_SELECT_PURPOSE_CURRENCY:
    "/app/agent/place-an-order/currency-exchange/select-purpose-currency",
  AGENT_PLACE_AN_ORDER_CURRENCY_EXCHANGE_SELECT_LOCATION:
    "/app/agent/place-an-order/currency-exchange/select-location",
  AGENT_PLACE_AN_ORDER_CURRENCY_EXCHANGE_CUSTOMER_DETAILS:
    "/app/agent/place-an-order/currency-exchange/customer-details",
  AGENT_PLACE_AN_ORDER_CURRENCY_EXCHANGE_CHECKLIST:
    "/app/agent/place-an-order/currency-exchange/checklist",
  AGENT_PLACE_AN_ORDER_CURRENCY_EXCHANGE_ORDER_REVIEW:
    "/app/agent/place-an-order/currency-exchange/order-review",
  AGENT_PLACE_AN_ORDER_CURRENCY_EXCHANGE_PAYMENT_MODE:
    "/app/agent/place-an-order/currency-exchange/payment-mode",
  AGENT_PLACE_AN_ORDER_CURRENCY_EXCHANGE_ORDER_RECEIVED:
    "/app/agent/place-an-order/currency-exchange/order-received",
  AGENT_PLACE_AN_ORDER_TRAVEL_CARD_MAIN:
    "/app/agent/place-an-order/travel-card",
  AGENT_PLACE_AN_ORDER_TRAVEL_CARD_SELECT_LOCATION:
    "/app/agent/place-an-order/travel-card/select-location",
  AGENT_PLACE_AN_ORDER_TRAVEL_CARD_CUSTOMER_DETAILS:
    "/app/agent/place-an-order/travel-card/customer-details",
  AGENT_PLACE_AN_ORDER_TRAVEL_CARD_CHECKLIST:
    "/app/agent/place-an-order/travel-card/checklist",
  AGENT_PLACE_AN_ORDER_TRAVEL_CARD_ORDER_REVIEW:
    "/app/agent/place-an-order/travel-card/order-review",
  AGENT_PLACE_AN_ORDER_TRAVEL_CARD_PAYMENT_MODE:
    "/app/agent/place-an-order/travel-card/payment-mode",
  AGENT_PLACE_AN_ORDER_TRAVEL_CARD_ORDER_RECEIVED:
    "/app/agent/place-an-order/travel-card/order-received",


  // AGENT_BRANCH
  AGENT_BRANCH_DASHBOARD: "/app/agent/dashboard",
  AGENT_BRANCH_INDEX: "/app/agent",

  // CHANNEL_PARTNER
  CHANNEL_PARTNER_INDEX: "/app/cpartner",
  CHANNEL_PARTNER_DASHBOARD: "/app/cpartner/dashboard",
  CHANNEL_PARTNER_MANAGE_ORDERS: "/app/cpartner/manage-orders",
  CHANNEL_PARTNER_MANAGE_CURRENCIES: "/app/cpartner/manage-currencies",

  CHANNEL_PARTNER_SETTINGS_MAIN: "/app/cpartner/settings",
  CHANNEL_PARTNER_SETTINGS_MY_PROFILE: "/app/cpartner/settings/my-profile",
  CHANNEL_PARTNER_SETTINGS_CHANGE_PASSWORD:
    "/app/cpartner/settings/change-password",

  CHANNEL_PARTNER_NOTIFICATIONS: "/app/cpartner/notifications",

  ERROR_403: "/403",
};
export default PAGE_NAMES;
