import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { replace } from "connected-react-router";

import ROUTE_PATH from "../../route/routes";
import AuthService from "../../services/auth.service";

interface UserState {
  isAuthenticated: boolean;
  isAppInitialized: boolean;
  user: any;
  error: string;
  loading: boolean;
  profile: any;
  profileLoading: boolean;
}

const initialState: UserState = {
  isAuthenticated: false,
  isAppInitialized: false,
  user: {},
  error: "",
  loading: false,
  profile: {},
  profileLoading: false,
};

export const login = createAsyncThunk(
  "user/login",
  async (params: any, { rejectWithValue, dispatch }) => {
    try {
      const response: any = await AuthService.login(params);
      if (response?.statusCode === 200 && response?.data) {
        if (params.rememberme) {
          localStorage.setItem(
            "login-data",
            CryptoJS.AES.encrypt(
              JSON.stringify(params),
              process.env.REACT_APP_PRIVATE_KEY || "iD@dmin2022",
            ).toString(),
          );
        } else {
          localStorage.removeItem("login-data");
        }

        dispatch(getProfile());
        return response;
      } else {
        toast.error(response?.message || "Something went wrong");
        return rejectWithValue(response?.message || "");
      }
    } catch (err: any) {
      toast.error(err?.message || "Something went wrong");
      return rejectWithValue(err.message || "Something went wrong");
    }
  },
);

export const logout = createAsyncThunk("user/logout", (_, { dispatch }) => {
  try {
    localStorage.removeItem("auth-access-token");
    localStorage.removeItem("auth-refresh-token");
    localStorage.removeItem("user");
    localStorage.removeItem("visitorId");
    dispatch(replace(ROUTE_PATH.PUBLIC.HOME));
    return true;
  } catch (err: any) {
    throw err;
  }
});

export const sender = createAsyncThunk("user/sender", (params:any, { dispatch,rejectWithValue }) => {
  try {
    dispatch(getProfile());
    return params;
  } catch (err: any) {
    return rejectWithValue(err.message || "Something went wrong");

  }
});

export const checkAuthorization = createAsyncThunk(
  "user/checkAuthorization",
  (_, { dispatch, rejectWithValue }) => {
    try {
      const tmpAT = localStorage.getItem("auth-access-token") ?? "";
      const tmpRT = localStorage.getItem("auth-refresh-token") ?? "";
      const tmpUserStr = localStorage.getItem("user") ?? "";
      if (tmpAT && tmpRT && tmpUserStr) {
        // let path;
        // switch (JSON.parse(tmpUserStr)?.role) {
        //   case UserTypes.CUSTOMER:
        //     path = `${ROUTE_PATH.PRIVATE.CUSTOMER.INDEX}/${ROUTE_PATH.PRIVATE.CUSTOMER.DASHBOARD}`;
        //     break;

        //   case UserTypes.AGENT:
        //     path = `${ROUTE_PATH.PRIVATE.AGENT.INDEX}/${ROUTE_PATH.PRIVATE.AGENT.DASHBOARD}`;
        //     break;
        // }
        // dispatch(replace(path));
        dispatch(getProfile());
        let usr = JSON.parse(tmpUserStr);
        return usr;
      } else {
        return rejectWithValue("No token found");
        // throw new Error();
      }
    } catch (err: any) {
      return rejectWithValue(err.message || "Something went wrong");
    }
  },
);

export const getProfile = createAsyncThunk(
  "user/getProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response: any = await AuthService.getProfile();
      if (response?.statusCode === 200 && response?.data) {
        return response.data;
      } else {
        return rejectWithValue(response?.message || "");
      }
    } catch (err: any) {
      return rejectWithValue(err.message || "Something went wrong");
    }
  },
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveCurrentUser(state, action: PayloadAction<any>) {
      state.isAuthenticated = true;
      state.isAppInitialized = true;
      state.user = action.payload.user;
    },
    clearError(state) {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkAuthorization.fulfilled, (state, action) => {
      console.log(action.payload);
      
      state.isAuthenticated = true;
      state.isAppInitialized = true;
      state.user = action.payload;
    });
    builder.addCase(checkAuthorization.rejected, (state) => {
      state.isAuthenticated = false;
      state.isAppInitialized = true;
    });
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload.data || {};
      state.error = "";
      state.isAuthenticated = true;
      state.isAppInitialized = true;
      state.loading = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.error = (action?.payload as string) || "";
      state.isAppInitialized = true;
      state.isAuthenticated = false;
      state.loading = false;
    });
    builder.addCase(getProfile.pending, (state) => {
      state.profileLoading = true;
      state.error = "";
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profile = action.payload.user || {};
      state.error = "";
      state.profileLoading = false;
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.error = (action?.payload as string) || "";
      state.profile = {};
      state.profileLoading = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.isAuthenticated = false;
      state.user = {};
      state.profile = {};
      state.isAppInitialized = true;
      state.loading = false;
    });
    builder.addCase(logout.rejected, (state) => {
      state.error = "something went wrong";
    });
    builder.addCase(sender.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(sender.fulfilled, (state, action) => {
      state.user = action.payload || {};
      state.error = "";
      state.isAuthenticated = true;
      state.isAppInitialized = true;
      state.loading = false;
    });
    builder.addCase(sender.rejected, (state, action) => {
      state.error = (action?.payload as string) || "";
      state.isAppInitialized = true;
      state.isAuthenticated = false;
      state.loading = false;
    });
  },
});

export const { saveCurrentUser, clearError } = userSlice.actions;
export default userSlice.reducer;
