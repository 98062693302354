import React from "react";

import profileImg from "../../../assets/img/default-user.png";
import { useAppSelector } from "../../../store/hooks";
import { Link, useLocation } from "react-router-dom";
import useUserNavigate from "../../../utils/useUserNavigate";

const ChannelPartnerHeader: React.FC<any> = () => {
  const { profile } = useAppSelector((state: any) => state.user);
  const { userNavigateLink } = useUserNavigate();
  const location = useLocation();

  return (
    <div className="section-header">
      <div className="Divright">
        <ul>
          <li>
            <div className="user-holder">
              <div className="mr-2">
                <Link
                  to={userNavigateLink("notifications")}
                  className="no-head-notify Box-round">
                  <i className="fa fa-bell-o" aria-hidden="true"></i>
                </Link>
              </div>
              <div className="user-img Box-round">
                <img
                  src={profileImg}
                  style={{ width: "50px", height: "50px" }}
                  alt=""
                />
              </div>
              <div className="user-cnt">
                <h2>
                  {profile?.firstName} {profile?.lastName}
                </h2>
                <p>{profile?.email}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      {location?.pathname === "/app/cpartner/dashboard" && (
        <div className="pg-header">
          <h1 className="m">
            Hi, {profile?.firstName} {profile?.lastName}
          </h1>
          <p>Welcome back, have a nice day!</p>
        </div>
      )}
    </div>
  );
};

export default ChannelPartnerHeader;
