import React from "react";

const ConfirmBox: React.FC<any> = (props: any) => {
  const { message, onClose, onProceed } = props;
  return (
    <div
      className="react-confirm-alert-body"
      style={{ zIndex: 999999999999999 }}>
      <p>{message}</p>
      <div
        style={{ width: "100%" }}
        className="d-flex justify-content-end mt-4">
        <button
          className="mr-1 btn btn-sm btn-primary-g"
          onClick={() => {
            onProceed();
            onClose();
          }}>
          Yes
        </button>
        <button className="btn btn-sm btn-primary-b-g" onClick={onClose}>
          No
        </button>
      </div>
    </div>
  );
};

export default ConfirmBox;
